.group {
  position: relative;
  height: 1.8em;
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  text-align: right;
  line-height: 1.8em;
  padding-left: 0.4em;
  padding-right: 0.4em;
  border-radius: 3px;
}

.ufAimHome {
  background-color: #00cc00 !important;
  color: #fff !important;
}

.ufAimOverseas {
  background-color: #009900 !important;
  color: #fff !important;
}

.baseline {
  background-color: #cc0088 !important;
  color: #fff !important;
}

.lower {
  background-color: #ee5500 !important;
  color: #fff !important;
}

.predicted {
  background-color: #ee9933 !important;
  color: #fff !important;
}

.upper {
  background-color: #ffcc77 !important;
  color: #fff !important;
}
