.portal {
  /* header z-index + 1 */
  z-index: 10001;
  position: fixed;
  right: 0;
  /* header height */
  top: 40px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}

.notificationContainer {
  padding: 14px;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-width: 500px;
}
